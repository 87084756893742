const Colors = {
  white: '#ffffff',
  light: '#f6f1ed',
  lightGrey: '#e5dada',
  dark: '#b5a397',
  darker: '#6a5750',
  darkest: '#30231d',
  closeIcon: '#969ea4',
  lilacBackground: '#6C63FF',
  lilacLightBackground: '#e1dfff',
  shadow: 'rgba(57, 55, 55, 0.08)',
  darkM_Background: '#2E3440',
  darkM_Input_Background: '#3e4657',
  darkM_Secondary: '#ff9800',
  darkM_Text: '#EBEFF4',
  darkM_Text_Darken: 'white',
  darkM_Shadow: '#222730'
}

export default Colors
