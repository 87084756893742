import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { darken, lighten } from 'polished'
import React from 'react'
import 'react-toggle/style.css'
import styled, { withTheme } from 'styled-components'

import { Button, HireMe } from 'components/Button'

import Colors from '../utils/Colors'
import { media, mediaMin } from '../utils/MediaQueries'
import * as Mixins from '../utils/Mixins'
import * as t from '../utils/Typography'

const HeaderWrapper = styled.div`
  height: auto;
  max-height: 48px;
  min-height: 16px;
  padding: 27px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: ${Colors.white};
  background-color: ${(props) => {
    if (props.scrolled) {
      return props.theme === 'white' ? `${Colors.white};` : `${Colors.darkM_Background};`
    }
    return 'transparent'
  }};
  ${(props) => {
    if (props.scrolled) {
      return props.theme === 'white'
        ? 'box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.1);'
        : 'box-shadow: 0 2px 24px 0 rgba(1, 1, 1, 1.1);'
    }
    return undefined
  }}

  ${t.P} {
    color: ${(props) => (props.scrolled || props.theme === 'white') && `${Colors.darkest};`} ${media.desktop`
        color: ${Colors.white};
      `};
  }
  ${Button} {
    color: ${(props) => props.scrolled && `${Colors.darkest}`};
    border: 1px solid ${(props) => props.scrolled && `${Colors.darkest}`};
    &:hover {
      color: ${(props) => props.scrolled && `${lighten(0.3, Colors.darkest)}`};
      border: 1px solid ${(props) => props.scrolled && `${lighten(0.3, Colors.darkest)}`};
    }
    ${media.menuMax`
      padding: 15px 20px;
    `}
    ${media.desktop`
      color: ${Colors.white};
      border: 1px solid ${Colors.white};
      &:hover {
        color: ${(props) => props.scrolled && `${darken(0.3, Colors.white)}`};
        border: 1px solid ${(props) => props.scrolled && `${darken(0.3, Colors.white)}`};
      }
    `}
  }
  ${HireMe} {
    margin-left: 20px;
    ${media.desktop`
      margin: 30px 0 0 0;
    `}
  }
`

const Logo = styled.a`
  position: absolute;
  left: 30px;
  top: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  align-self: baseline;
  ${mediaMin.desktop`
    position: relative;
    left: auto;
    top: 0;
  `}
`

const AvatarImg = styled(GatsbyImage)`
  transition-duration: 0.4s;
  &:hover {
    transform: scale(1.3);
  }
`

const HeaderNav = styled.div`
  position: absolute;
  right: 30px;
  top: 16px;
  max-width: 43px;
  height: 40px;
  img {
    width: 100%;
    transition-duration: 0.4s;
  }
  ${mediaMin.desktop`
    position: relative;
    right: auto;
    top: 0;
  `}
`

const ContentWrapper = styled.div`
  ${Mixins.contentMixin};
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const DarkModeButton = styled.button`
  &:hover:not(:disabled) {
    background-color: rgba(236, 239, 244, 0.4);
  }

  &:active:focus:hover {
    outline: 0;
  }

  user-select: none;
  cursor: pointer;
  pointer-events: auto;
  background-color: transparent;
  margin-left: 0.5em;
  position: relative;
  padding: 1em;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 0.25em;
  transition: opacity 0.5s, margin-top 0.5s, margin-bottom 0.5s;
  overflow: hidden;
  margin-right: 0.5rem;
`

const LightModeButton = styled.button`
  &:hover:not(:disabled) {
    background-color: rgba(76, 86, 106, 0.8);
  }

  &:active:focus:hover {
    outline: 0;
  }

  user-select: none;
  cursor: pointer;
  pointer-events: auto;
  background-color: transparent;
  margin-left: 0.5em;
  position: relative;
  padding: 1em;
  border-width: 0;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 0.25em;
  overflow: hidden;
  transition: background-color 100ms ease-in-out 0s;
  margin-right: 0.5rem;
`

const StyledFAwIcon = styled(FontAwesomeIcon)`
  &:active {
    transform: translateY(-100px);
  }
  transition: transform 2s ease-out;
`

// const options = [{ value: 'ES', label: '🇪🇸 Spanish' }, { value: 'EN', label: '🇬🇧 English' }];

function AvatarHeader() {
  return (
    <StaticQuery
      query={graphql`
        query {
          profile: file(relativePath: { eq: "profile.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 64, height: 64, quality: 100, placeholder: BLURRED)
            }
          }
        }
      `}
      render={(data) => (
        <AvatarImg image={getImage(data.profile)} alt="Name Surname" imgStyle={{ borderRadius: '50%' }} />
      )}
    />
  )
}
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
    }
  }

  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const doc = document.documentElement
    const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    if (scrollTop >= 100) {
      this.setState({ scrolled: true })
    } else if (scrollTop < 100) {
      this.setState({ scrolled: false })
    }
  }

  render() {
    const { scrolled } = this.state
    const { theme, onChangeTheme } = this.props
    const darkModeToggled = theme !== 'white'

    return (
      <HeaderWrapper theme={theme} scrolled={scrolled}>
        <ContentWrapper>
          <Logo href="/">
            <AvatarHeader />
          </Logo>
          <HeaderNav>
            {darkModeToggled ? (
              <LightModeButton onClick={onChangeTheme}>
                <StyledFAwIcon icon={faSun} size="lg" color={Colors.darkM_Secondary} />
              </LightModeButton>
            ) : (
              <DarkModeButton onClick={onChangeTheme}>
                <StyledFAwIcon icon={faMoon} size="lg" color={Colors.darkM_Background} />
              </DarkModeButton>
            )}
          </HeaderNav>
        </ContentWrapper>
      </HeaderWrapper>
    )
  }
}

export default withTheme(Header)
