import styled from 'styled-components'
import { lighten, darken } from 'polished'
import { Link } from 'gatsby'
import Colors from '../utils/Colors'

export const Button = styled(Link)`
  background: ${(props) => props.theme.bg};
  color: ${(props) => (props.theme === 'white' ? `${Colors.darkest};` : `${Colors.white};`)};
  line-height: 1.05;
  font-size: 16px;
  font-weight: bold;
  padding: ${(props) => (props.large ? '15px 34px;' : '12px 30px;')}
  border: 1px solid ${(props) => (props.theme === 'white' ? `${Colors.darkest};` : `${Colors.white};`)};
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  ${(props) => props.book && 'margin-left: 15px;'}
  &:hover {
    color: ${(props) =>
      props.theme === 'white' ? `${lighten(0.3, Colors.darkest)};` : `${darken(0.1, Colors.white)};`};
    text-decoration: none;
    background: transparent;
    border: 1px solid ${(props) =>
      props.theme === 'white' ? `${lighten(0.3, Colors.darkest)};` : `${darken(0.1, Colors.white)};`};
  }
`

export const HireMe = styled.div`
  line-height: 1.05;
  font-size: ${(props) => (props.large ? '18px' : '16px')};
  padding: ${(props) => (props.large ? '16px 50px' : '12px 30px')};
  border-radius: 100px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  background: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  color: white;
  font-weight: bold;
  border: 1px solid;
  border-color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  transition-duration: 0.3s;
  &:hover {
    --color : ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)}
    color: white;
    background: ${(props) => {
      const color = props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary
      return darken(0.1, color)
    }};
    border: 1px solid;
    border-color: ${(props) => {
      const color = props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary
      return darken(0.1, color)
    }};
  }
`
