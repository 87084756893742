import styled from 'styled-components'

import Colors from './Colors'
import { media } from './MediaQueries'
import * as Mixins from './Mixins'
import * as t from './Typography'

const Background = styled.div`
  background-color: ${(props) => (props.theme === 'white' ? Colors.white : Colors.darkM_Background)};
`

const AboveFold = styled.div`
  ${Mixins.aboveFoldMixin}
  padding: 140px 0 60px 0;
  ${t.H1} {
    color: ${(props) => (props.theme === 'white' ? Colors.darkest : Colors.darkM_Text)};
  }
  color: ${(props) => (props.theme === 'white' ? Colors.darkest : Colors.darkM_Text)};
`

const HomepageWrapper = styled.div`
  --box-shadow-color: ${(props) => (props.theme === 'white' ? Colors.shadow : Colors.darkM_Shadow)};
  box-shadow: 0 2px 26px 0 var(--box-shadow-color);
  ${Mixins.wrapper};
  background-color: ${(props) => (props.theme === 'white' ? Colors.white : Colors.darkM_Background)};
  .who-desc {
    display: block;
    margin: 0 auto 60px auto;
    max-width: 90%;
  }
  ${t.LargeP} {
    margin-bottom: 28px;
  }
  ${t.H1} {
    margin: 0 0 20px 0;
  }
  .avatar {
    max-width: 200px;
    width: 80%;
    margin: 0 auto 50px auto;
    border-radius: 50%;
    display: block;
  }
  .link {
    padding: 0;
    color: ${Colors.darkest};
    text-decoration: underline;
    svg {
      margin-left: 7px;
    }
  }
  .portfolio {
    margin: 100px 0 50px 0;
    font-size: 42px;
  }
`

const PostRow = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-bottom: 2rem;

  ${media.phone`
    display: block;
  `};
`

const PostImage = styled.div`
  opacity: 1;
  display: block;
  width: auto;
  height: 20rem;
  backface-visibility: hidden;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.05);
`

const PostTitle = styled.div`
  color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  text-decoration: none;
  font-weight: bold;
  margin-top: 1rem;
  font-size: 1.2rem;
`

const PostReadTime = styled.div`
  color: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  text-decoration: none;
  font-weight: bold;
  font-style: italic;
  margin-top: 0.5rem;
  font-size: 0.8rem;
`

const PostElement = styled.a`
  --box-shadow-color-accent: ${(props) => (props.theme === 'white' ? 'rgba(57, 55, 55, 0.4)' : Colors.darkM_Shadow)};
  --box-shadow-color: ${(props) => (props.theme === 'white' ? 'rgba(57, 55, 55, 0.08)' : Colors.darkM_Shadow)};
  display: flex;
  flex-direction: column;
  max-width: 23rem;
  justify-content: flex-start;
  text-decoration: none;
  padding: 1rem;
  border-radius: 20px;
  margin-right: 1rem;
  box-shadow: 0 2px 26px 0 var(--box-shadow-color);
  align-self: flex-start;
  transition: all 0.3s linear 0s;
  ${media.phone`
    margin-bottom: 2rem;
    margin-right: 0rem;
  `};
  &:hover {
    box-shadow: 0 2px 26px 0 var(--box-shadow-color-accent);
    transform: scaleX(1.05) scaleY(1.05);
  }
`

const Table = styled.ul`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

const TableProjects = styled.ul`
  ${media.phone`
    padding: 0px;
  `};
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`

export {
  Background,
  AboveFold,
  HomepageWrapper,
  PostRow,
  PostTitle,
  PostReadTime,
  PostElement,
  PostImage,
  Table,
  TableProjects,
}
