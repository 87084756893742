/* eslint-disable react/jsx-props-no-spreading */
import { faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

import Colors from '../utils/Colors'
import { media } from '../utils/MediaQueries'
import Content from './Content'

const FooterWrapper = styled.div`
  background: ${(props) => (props.theme === 'white' ? Colors.lilacBackground : Colors.darkM_Secondary)};
  color: ${Colors.white};
  padding: ${(props) => {
      if (props.big) {
        return '210px'
      }
      return props.medium ? '100px' : '80px'
    }}
    0 60px 0;
  ${media.tablet`padding: ${(props) => {
    if (props.big) {
      return '150px'
    }
    return props.medium ? '100px' : '128px'
  }} 20px 50px 20px;`}
`

const Copyright = styled.p`
  text-align: center;
`

const SocialText = styled.p`
  margin-left: 1em;
`

const SocialColumn = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  width: 33%;
  justify-content: center;
  &:hover {
    ${SocialText} {
      border-bottom: 2px solid;
      border-color: white;
    }
  }
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${media.phone`
    flex-direction: column
  `};
`

function Footer({ big, medium, theme }) {
  return (
    <FooterWrapper theme={theme} {...(big && { big })} {...(medium && { medium })}>
      <Content>
        <SocialWrapper>
          <SocialColumn href="https://www.github.com/manuelrdsg">
            <FontAwesomeIcon icon={faGithub} size="lg" color="white" />
            <SocialText>manuelrdsg</SocialText>
          </SocialColumn>
          <SocialColumn href="https://www.twitter.com/manuelrdsg">
            <FontAwesomeIcon icon={faTwitter} size="lg" color="white" />
            <SocialText>@manuelrdsg</SocialText>
          </SocialColumn>
          <SocialColumn href="https://www.linkedin.com/in/manuelrdsg/en">
            <FontAwesomeIcon icon={faLinkedin} size="lg" color="white" />
            <SocialText>Manuel Rodríguez-Sánchez Guerra</SocialText>
          </SocialColumn>
        </SocialWrapper>
        <Copyright white align="center">
          © Manuel Rodriguez-Sanchez 2023. All rights reserved.
        </Copyright>
        <Copyright white align="center">
          Made with ❤ and Gatsby.
        </Copyright>
      </Content>
    </FooterWrapper>
  )
}

export default Footer
