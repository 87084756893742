import styled from 'styled-components'
import * as Mixins from '../utils/Mixins'
import * as t from '../utils/Typography'
import Colors from '../utils/Colors'

const ContentWrapper = styled.div`
  ${Mixins.contentMixin}
  ${t.H2} {
    color: ${(props) => (props.theme === 'white' ? Colors.darkest : Colors.darkM_Text)};
  }
  p {
    color: ${(props) => (props.theme === 'white' ? Colors.darkest : Colors.darkM_Text)};
  }
`

export default ContentWrapper
