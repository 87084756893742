import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import GlobalStyles from '../utils/GlobalStyles'
import Footer from './Footer'
import Header from './Header'

export const LayoutWrapper = styled.div`
  position: relative;
`

function Layout({ children, theme, bigFooter, mediumFooter, openContactPopup, onChangeTheme }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <GlobalStyles />
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content:
                  "¡Hola! I'm Manuel Rodriguez-Sanchez. I'm a Cross-Platform Mobile Engineer, currently working as a Freelancer.",
              },
              { name: 'keywords', content: 'portfolio' },
            ]}>
            <html lang="en" />
          </Helmet>
          <Header theme={theme} onChangeTheme={onChangeTheme} openContactPopup={openContactPopup} />
          <LayoutWrapper>{children}</LayoutWrapper>
          <Footer theme={theme} big={bigFooter} medium={mediumFooter} openContactPopup={openContactPopup} />
        </>
      )}
    />
  )
}

export default Layout
